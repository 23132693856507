/********************************************************************************
**
**	This is a self initializing jQuery plugin designed to handle user interface
**	interactions.
**
********************************************************************************/

(function($){

	$.ui = function() {

		var self = {

			/********************************************************************************
			**	PROPERTIES
			********************************************************************************/

				// flash messages
				flashMessage:			'',
				flashMessageType:		'',
				flashMessageDismiss:	'',

				tinyUrl:				'',

			/********************************************************************************
			**	INIT METHODS
			********************************************************************************/

				init : function() {

					self.log('***************** init *****************');								

					var longUrl = window.location.href;
					self.getTinyUrl(longUrl);

					self.initEventListeners();

					if($('.mepr-signup-form').length > 0) {
						$('.mepr_first_name input').attr('placeholder', 'First Name');
						$('.mepr_last_name input').attr('placeholder', 'Last Name');
						$('.mepr_email input').attr('placeholder', 'Email');
						$('.mepr_password input').attr('placeholder', 'Password');
						$('.mepr_password_confirm input').attr('placeholder', 'Confirm Password');
						$('.mepr_math_captcha input').attr('placeholder', 'Enter Answer');
						setTimeout(function(){
							$('.mepr_coupon input').attr('placeholder', 'Discount Code');
						}, 100);
					}

					if($('.mp_login_form').length > 0) {
						$('.mepr_username input').attr('placeholder', 'Email');
						$('.mepr_password input').attr('placeholder', 'Password');
					}

					if($('#mepr_reset_password_form').length > 0) {
						$('.mepr_password input').attr('placeholder', 'New Password');
						$('.mepr_password_confirm input').attr('placeholder', 'Confirm New Password');
					}

					if($('.mepr-newpassword-form').length > 0) {
						$('.mepr_new_password input').attr('placeholder', 'New Password');
						$('.mepr_confirm_password input').attr('placeholder', 'Confirm New Password');
					}

					if($('.mepr_forgot_password_input').length > 0) {
						$('.mepr_forgot_password_input input').attr('placeholder', 'Email address');
					}

					if($('#mepr_account_form').length > 0) {
						$('.mepr_first_name input').attr('placeholder', 'First Name');
						$('.mepr_last_name input').attr('placeholder', 'Last Name');
						$('.mepr_email input').attr('placeholder', 'Email');
					}

					if($('#thank-you-modal').length > 0) {
						$('#thank-you-modal').modal('show');
					}

					if($('#tips-modal').length > 0) {

						if((localStorage.getItem('showTipsModal') === null) || (localStorage.getItem('showTipsModal') == false) || (localStorage.getItem('showTipsModal') == 'false')) {
							localStorage.setItem('showTipsModal', 0);
						}

						if(parseInt(localStorage.getItem('showTipsModal')) < 3) {

							setTimeout(function(){
								$('#tips-modal').modal('show');
							}, 3000);

						}

					}

					if($('.audio-player').length > 0) {

						var audio = $('.audio-player audio')[0];

						audio.ontimeupdate = function() {
							var percent = audio.currentTime / audio.duration * 100;
							$('.progress-bar').css('width', percent + '%');
							$('.audio-current-time').html(self.formatTime(audio.currentTime));
						}

						var firstMedigraytionTitle = $('.medigraytion.init').attr('data-medigraytion-title');

						$('.now-playing-medigraytion-title').html(firstMedigraytionTitle);

					}

				},

				initEventListeners : function() {

					self.log('***************** initEventListeners *****************');

					$(document).on('click', '.anatomy-guide', function(e){
						e.stopPropagation();
					});

					$(document).on('click', '.ui-action', function(e) {

						e.preventDefault();

                        var action = $(this).attr('data-action');

                        switch(action) {

                            case 'playMedigraytion':

								$('.audio-duration').html('');
								$('.marquee').marquee('destroy');

                            	$('.medigraytion').removeClass('active loading init');
								$(this).addClass('loading');

                            	var audio = $('.audio-player audio')[0];

                            	audio.pause();
                            	audio.currentTime = 0;

                            	var medigraytionSeries = $('h1.page-title').html();
                            	var medigraytionTitle = $(this).attr('data-medigraytion-title');
                            	var medigraytionUrl = $(this).attr('data-medigraytion-url');

								$('.now-playing-series-title').html('Loading...');
								$('.now-playing-medigraytion-title').html('');
                            	$('.audio-player audio source').attr('src', medigraytionUrl);

                            	audio.load();
                            	audio.play();

								var currentTime = 0;
								var audioTimeCheck = setInterval(function(){
									currentTime = audio.currentTime;
									if(currentTime > 0) {
		                            	var loadingMedigraytionTitle = $('.medigraytion.loading').attr('data-medigraytion-title');
										$('.audio-controls .audio-play-pause').html('<i class="fa fa-pause"></i>').removeClass('stopped').addClass('playing');
										$('.now-playing-series-title').html(medigraytionSeries+' &mdash; ');
										$('.now-playing-medigraytion-title').html(loadingMedigraytionTitle);
										$('.medigraytion.loading').removeClass('loading').addClass('active');
										$('.marquee').marquee('destroy');
										$('.marquee').marquee({
											duration: 150000,
											speed: 40,
											gap: 50,
											delayBeforeStart: 1000,
											direction: 'left',
											duplicated: true,
											startVisible: true
										});
										clearInterval(audioTimeCheck);
									}
								}, 1000);

								var audioDuration = '';
								var audioDurationCheck = setInterval(function(){
									audioDuration = self.formatTime(audio.duration);
									if(audioDuration != '0NaN:0NaN') {
										$('.audio-duration').html(audioDuration);
										clearInterval(audioDurationCheck);
									}
								}, 1000);

								// Record the custom GA4 event
								var eventLabel = medigraytionSeries+' - '+medigraytionTitle;
								window.dataLayer = window.dataLayer || [];
    							window.dataLayer.push({
      								event: 'play_medigraytion',
      								medigraytion_name: eventLabel
    							});

								break;

                            case 'playPauseMedigraytion':

								if($('.medigraytion.init').length > 0) {

									$('.medigraytion.init').trigger('click');

								} else {

									var audio = $('.audio-player audio')[0];

									if($(this).hasClass('stopped')) {
		                            	audio.play();
		                            	$(this).removeClass('stopped').addClass('playing');
		                            	$(this).html('<i class="fa fa-pause"></i>');
		                            	$('.medigraytion.active .medigraytion-audio-playing span').css('animation-play-state', 'running');
	                            	} else if($(this).hasClass('playing')) {
		                            	audio.pause();
		                            	$(this).removeClass('playing').addClass('stopped');
		                            	$(this).html('<i class="fa fa-play"></i>');
		                            	$('.medigraytion.active .medigraytion-audio-playing span').css('animation-play-state', 'paused');
	                            	}

								}

                            	break;

                            case 'playPreviousMedigraytion':

								if($('.audio-shuffle').hasClass('active')) {
									var medigraytions = $('.medigraytion:not(active)');
									$(medigraytions[Math.floor(Math.random()*medigraytions.length)]).click();
								} else {
									if($('.medigraytion.active').length > 0) {
										$('.medigraytion.active').prev().trigger('click');
									} else {
										$('.medigraytion.loading').prev().trigger('click');
									}
								}

                            	break;

                            case 'playNextMedigraytion':

								if($('.audio-shuffle').hasClass('active')) {
									var medigraytions = $('.medigraytion:not(active)');
									$(medigraytions[Math.floor(Math.random()*medigraytions.length)]).click();
								} else {
									if($('.medigraytion.active').length > 0) {
										$('.medigraytion.active').next().trigger('click');
									} else {
										$('.medigraytion.loading').next().trigger('click');
									}
								}

                            	break;

                            case 'repeatMedigraytion':

                            	if($('.audio-repeat').hasClass('active')) {
									$('.audio-repeat').removeClass('active');
	                			} else {
		                			$('.audio-repeat').addClass('active');
									$('.audio-shuffle').removeClass('active');
	                			}

                            	break;

							case 'shuffleMedigraytion':

								if($('.audio-shuffle').hasClass('active')) {
									$('.audio-shuffle').removeClass('active');
								} else {
									$('.audio-shuffle').addClass('active');
									$('.audio-repeat').removeClass('active');
								}

                            	break;

                            case 'redirectToSignup':

                            	window.location.href = '/signup';

                            	break;

                            case 'setAudioPosition':

                            	var audio = $('.audio-player audio')[0];

                            	var progressBarWidth = $(this).css('width').slice(0, -2);
                            	var progressBarPositionLeft = $('.progress').offset().left;
                            	var clickPosition = e.pageX - progressBarPositionLeft;
                            	var percentage = clickPosition / progressBarWidth;

                            	audio.currentTime = audio.duration * percentage;

                            	break;

                            case 'hideFlashMessage':

                            	self.hideFlashMessage();

                            	break;

                            case 'toggleNav':

                            	self.toggleNav();

                            	break;

                            case 'share':

                            	var sharingPlatform = $(this).attr('data-sharing-platform');

                            	if(sharingPlatform == 'email') {

	                            	$('#email-share-modal').modal('show');

	                            	// old implementation when sharing individual medigraytions, leaving for future reference.
	                            	/*var productImageUrl = $(this).attr('data-product-image-url');
	                            	var productTitle = $(this).attr('data-product-title');
	                            	var productTitleUrl = window.location.href;

	                            	$('#nf-field-14').val(productImageUrl).trigger('change');
	                            	$('#nf-field-15').val(productTitle).trigger('change');
	                            	$('#nf-field-16').val(productTitleUrl).trigger('change');*/

                            	} else if(sharingPlatform == 'facebook') {
	                            	var facebookUrl = 'http://www.facebook.com/sharer.php?u='+window.location.href;
	                            	window.open(facebookUrl, 'share', 'height=360,width=500');
                            	} else if(sharingPlatform == 'twitter') {
	                            	var twitterUrl = 'http://twitter.com/intent/tweet?text=Medigraytions are a pioneering new way to heal oneself. Check it out. '+self.tinyUrl+'&hashtags=medigraytion';
	                            	window.open(twitterUrl, 'share', 'height=360,width=500');
                            	}

                            	break;

                        }

                    });

                    $('#audio-player-main').on('ended', function(){

	                	if($('.audio-repeat').hasClass('active')) {
		                	$('.medigraytion.active').trigger('click');
		                	$('.medigraytion.active .medigraytion-audio-playing span').css('animation-play-state', 'running');
	                	} else {
							if($('.audio-shuffle').hasClass('active')) {
								var medigraytions = $('.medigraytion:not(active)');
								$(medigraytions[Math.floor(Math.random()*medigraytions.length)]).click();
							} else {
								if($('.medigraytion.active').length > 0) {
									$('.medigraytion.active').next().trigger('click');
								} else {
									$('.medigraytion.loading').next().trigger('click');
								}
							}
	                	}

                    });

                    $(document).on('shown.bs.modal', '#tips-modal', function(){

						var showTipsModalCount = parseInt(localStorage.getItem('showTipsModal'));
						var newShowTipsModalCount = showTipsModalCount + 1;

						if(newShowTipsModalCount < 4) {
							localStorage.setItem('showTipsModal', newShowTipsModalCount);
						}

                    });

					$(document).on('click', '.do-not-show', function(){

						localStorage.setItem('showTipsModal', 3);

						$('#tips-modal').modal('hide');

					});

					$(document).on('DOMContentLoaded', function(){
						var dropdowns = document.querySelectorAll('.dropdown-toggle');

						dropdowns.forEach(function (dropdown) {
							dropdown.addEventListener('click', function (event) {
								event.preventDefault();
								var dropdownMenu = this.nextElementSibling;
								if (dropdownMenu.classList.contains('show')) {
									dropdownMenu.classList.remove('show');
								} else {
									// Hide other dropdowns
									document.querySelectorAll('.dropdown-menu').forEach(function (element) {
										element.classList.remove('show');
									});
									// Show this dropdown
									dropdownMenu.classList.add('show');
								}
							});
						});

						// Optional: Close dropdowns when clicking outside
						document.addEventListener('click', function (event) {
							if (!event.target.matches('.dropdown-toggle')) {
								document.querySelectorAll('.dropdown-menu').forEach(function (element) {
									element.classList.remove('show');
								});
							}
						});
					});

				},

			/********************************************************************************
			**	RENDER METHODS
			********************************************************************************/



            /********************************************************************************
			**	GENERAL METHODS
			********************************************************************************/

				log : function(message) {
					if(location.host != "www.medigraytion.com" && location.host != "medigraytion.com") {
            			console.log(message);
          			}
				},

				formatTime : function(seconds) {

					minutes = Math.floor(seconds / 60);
					minutes = (minutes >= 10) ? minutes : "0" + minutes;
					seconds = Math.floor(seconds % 60);
					seconds = (seconds >= 10) ? seconds : "0" + seconds;

					return minutes + ":" + seconds;

				},

				toggleNav : function() {

					var rightOpenPosition = '-400px';
					var windowWidth = $(window).width();

					if(windowWidth < 600) {
						rightOpenPosition = '-'+windowWidth+'px';
						$('#collapsible-navigation-wrapper').css('width', windowWidth+'px');
					}

					if($('#collapsible-navigation-wrapper').css('right') == '0px') { // menu is open, going to close
						$('#collapsible-navigation-wrapper').animate({'right':rightOpenPosition}, 300, function(){
							$('#collapsible-navigation-wrapper').css('width', '400px');
						});
						$('.close-collapsible-navigation').animate({'opacity':0}, 300);
					} else { // menu is closed, going to open
						$('#collapsible-navigation-wrapper').animate({'right':'0px'}, 300);
						$('.close-collapsible-navigation').animate({'opacity':1}, 1500);
					}

				},

				showFlashMessage : function(flashMessage, flashMessageType, flashMessageDismiss) {

					flashMessage = flashMessage || '';
					flashMessageType = flashMessageType || '';
					flashMessageDismiss = flashMessageDismiss || '';

					if(flashMessage != '') {
						self.flashMessage = flashMessage;
						self.flashMessageType = flashMessageType;
						self.flashMessageDismiss = flashMessageDismiss;
					}

					var flashMessageWrapper = $('#flash-message-wrapper');
					var flashMessageElement = $('.flash-message');

					var animateHeight;
					var iconClass;

					if(self.flashMessageType == 'success') {
						iconClass = 'fa-check-circle';
					} else if(self.flashMessageType == 'info') {
						iconClass = 'fa-info-circle';
					} else if(self.flashMessageType == 'warning') {
						iconClass = 'fa-exclamation-triangle';
					} else if(self.flashMessageType == 'danger') {
						iconClass = 'fa-times-circle';
					}

					flashMessageWrapper.removeClass();
					flashMessageWrapper.addClass(self.flashMessageType);

					flashMessageElement.html('<i class="fa '+iconClass+'" aria-hidden="true"></i> '+self.flashMessage);
					animateHeight = flashMessageElement.height() + 20;

					flashMessageWrapper.animate({'height':animateHeight}, 300);

					if(self.flashMessageDismiss == 'auto') {

						setTimeout(function(){
							$('.dismiss-flash-message').trigger('click');
						}, 3000);

					}

					self.flashMessage = '';
					self.flashMessageType = '';
					self.flashMessageDismiss = '';

				},

				hideFlashMessage : function() {

					$('#flash-message-wrapper').animate({'height': 0}, 300, function(){
						$('.flash-message').html('');
					});

				},

				getTinyUrl : function(longUrl) {

					var self = this;
					self.tinyUrl = longUrl;

					// Google's url shortener is deprecated
					/*$.ajax({
						url: 'https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyCbq_WtSas5LcBUzMIHtNBDuYa1_jb_BdU',
						type: 'POST',
						contentType: 'application/json; charset=utf-8',
						data: '{ longUrl: "' + longUrl +'"}',
						dataType: 'json',
						success: function(response) {
						    self.tinyUrl = response.id;
						    self.log(self.tinyUrl);
						},
						error: function(response) {
					    	self.tinyUrl = longUrl;
					    	self.log(self.tinyUrl);
					    }
					});*/

				},

		};

		/********************************************************************************
		**	FUNCTION MAPPING
		********************************************************************************/

		return {

			init: 										self.init,

		};

	};

	$.ui().init();

})(jQuery);
